import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { FisLoadingIndicator } from '@fis/ui-loading-indicator';
import { useCurrentUser } from '@fis/data-current-user';

export const Page = () => {
  const { replace } = useRouter();
  const [currentUser] = useCurrentUser();

  useEffect(() => {
    if (replace && currentUser?.uuid) {
      replace(`/educators/${currentUser.uuid}`);
    }
  }, [replace, currentUser]);
  return <FisLoadingIndicator />;
};

export default Page;
