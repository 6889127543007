import { styled, keyframes } from '@fis/util-theme';

/* -------------------------------------------------------------------------------------------------
 * LoaderContainer
 * ------------------------------------------------------------------------------------------------*/

const LoaderContainer = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignContent: 'center',
  padding: '120px 0 40px 0',
  margin: 'auto',
});

/* -------------------------------------------------------------------------------------------------
 * LoaderSlash
 * ------------------------------------------------------------------------------------------------*/

const slash1 = keyframes({
  '0%': { opacity: '.05' },
  '50%': { opacity: '.1', transform: 'translateX(12px)' },
  '100%': { opacity: '.1', transform: 'translateX(12px)' },
});

const slash2 = keyframes({
  '0%': { opacity: '.1' },
  '50%': { opacity: '.15', transform: 'translateX(12px)' },
  '100%': { opacity: '.15', transform: 'translateX(12px)' },
});

const slash3 = keyframes({
  '0%': { opacity: '.15' },
  '50%': { opacity: '.2', transform: 'translateX(12px)' },
  '100%': { opacity: '.2', transform: 'translateX(12px)' },
});

const slash4 = keyframes({
  '0%': { opacity: '.2' },
  '50%': { opacity: '.25', transform: 'translateX(12px)' },
  '100%': { opacity: '.25', transform: 'translateX(12px)' },
});

const slash5 = keyframes({
  '0%': { opacity: '.25' },
  '50%': { opacity: '.3', transform: 'translateX(12px)' },
  '100%': { opacity: '.3', transform: 'translateX(12px)' },
});

const slash6 = keyframes({
  '0%': { opacity: '.35' },
  '50%': { opacity: '1', transform: 'translateX(12px)' },
  '100%': { opacity: '1', transform: 'translateX(12px)' },
});

const slash7 = keyframes({
  '0%': { opacity: '1' },
  '50%': { opacity: '1', transform: 'translateX(12px)' },
  '100%': { opacity: '1', transform: 'translateX(12px)' },
});

const slash8 = keyframes({
  '0%': { opacity: '1' },
  '25%': { opacity: '0', transform: 'translateX(12px)' },
  '50%': { opacity: '0', transform: 'translateX(12px)' },
  '100%': { opacity: '0', transform: 'translateX(12px)' },
});

const LoaderSlash = styled('span', {
  width: '13px',
  height: '32px',
  margin: 'auto 0',

  variants: {
    slash1: {
      true: {
        animation: `${slash1} 1s infinite`,
      },
    },
    slash2: {
      true: {
        animation: `${slash2} 1s infinite`,
      },
    },
    slash3: {
      true: {
        animation: `${slash3} 1s infinite`,
      },
    },
    slash4: {
      true: {
        animation: `${slash4} 1s infinite`,
      },
    },
    slash5: {
      true: {
        animation: `${slash5} 1s infinite`,
      },
    },
    slash6: {
      true: {
        animation: `${slash6} 1s infinite`,
      },
    },
    slash7: {
      true: {
        animation: `${slash7} 1s infinite`,
      },
    },
    slash8: {
      true: {
        animation: `${slash8} 1s infinite`,
      },
    },
  },
});

const SlashIcon = () => {
  return (
    <svg
      width="26"
      height="32"
      viewBox="0 0 26 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.453125 31.9163H5.76648L15.0035 0.216309H9.69018L0.453125 31.9163Z"
        fill="#4098FF"
      />
    </svg>
  );
};

const FisLoadingIndicator = () => {
  return (
    <LoaderContainer role="alert" aria-busy aria-label="Loading">
      <LoaderSlash slash1>
        <SlashIcon />
      </LoaderSlash>
      <LoaderSlash slash2>
        <SlashIcon />
      </LoaderSlash>
      <LoaderSlash slash3>
        <SlashIcon />
      </LoaderSlash>
      <LoaderSlash slash4>
        <SlashIcon />
      </LoaderSlash>
      <LoaderSlash slash5>
        <SlashIcon />
      </LoaderSlash>
      <LoaderSlash slash6>
        <SlashIcon />
      </LoaderSlash>
      <LoaderSlash slash7>
        <SlashIcon />
      </LoaderSlash>
      <LoaderSlash slash8>
        <SlashIcon />
      </LoaderSlash>
    </LoaderContainer>
  );
};

FisLoadingIndicator.displayName = FisLoadingIndicator;
/* -----------------------------------------------------------------------------
 * Exports
 * ---------------------------------------------------------------------------*/

const Root = FisLoadingIndicator;

export {
  FisLoadingIndicator,
  //
  Root,
};
